export const BASE_URL = `https://${process.env.REACT_APP_BASE_URL}/v1`;
export const BASE_URL_TRANSACTIONS = `https://${process.env.REACT_APP_BASE_URL}/v3`;
export const HECTRONIC_WEBSITE_URL = 'https://www.hectronic.com';

const BASE_URL_USERS = `${BASE_URL}/users`;
const BASE_URL_MANDATORS = `${BASE_URL}/mandators`;
export const BASE_URL_DIGITAL_RECEIPT = `${BASE_URL}/digitalreceipts`;
export const BASE_URL_TWINT = `${BASE_URL}/twint`;
export const BASE_URL_FREEPARKING = `${BASE_URL}/parking/freeparking`;
const STORAGE_URL = `https://${process.env.REACT_APP_STORAGE_URL}/default`;
const FILE_MANAGEMENT_URL = `${process.env.REACT_APP_FILE_MANAGEMENT_URL}/files`;
export const BASE_URL_FLEETNET = `${BASE_URL}/fleetnet`;

export const URLS = {
    // CORE SQUAD ROUTES
    Users: BASE_URL_USERS,
    Mandators: BASE_URL_MANDATORS,
    MandatorsHistory: `${BASE_URL_MANDATORS}/history`,
    Services: `${BASE_URL_USERS}/services`,
    Rights: `${BASE_URL_USERS}/rights`,
    Components: `${BASE_URL_USERS}/components`,
    UsersHistory: `${BASE_URL_USERS}/history`,
    Sites: `${BASE_URL}/sites`,
    Devices: `${BASE_URL}/devices`,
    Points: `${BASE_URL}/points`,
    Products: `${BASE_URL}/products`,
    DeviceModels: `${BASE_URL}/device_models`,
    DeviceLogs: `${BASE_URL}/deviceconfiguration/logs/`,
    Roles: `${BASE_URL_USERS}/roles`,
    Prices: `${BASE_URL}/sites/prices`,
    Transactions: `${BASE_URL_TRANSACTIONS}/transactions`,
    TransactionsStatistics: `${BASE_URL_TRANSACTIONS}/transactions/statistics`,
    Protocols: `${BASE_URL}/protocols`,
    Manufacturers: `${BASE_URL}/manufacturers`,
    ManageFiles: `${FILE_MANAGEMENT_URL}`,
    Cards: `${BASE_URL}/payments/cards`,
    RemoteControlActions: `${BASE_URL}/remote_control/actions`,
    Providers: `${BASE_URL}/providers`,
    ProviderFields: `${BASE_URL}/providers/fields`,
    ProviderTemplates: `${BASE_URL}/providers/templates`,
    EulaFile: `${STORAGE_URL}/EULA`,
    SitesHistory: `${BASE_URL}/history`,
    TransactionsReport: `${FILE_MANAGEMENT_URL}/transactions/exports`,
    TwintSites: `${BASE_URL_TWINT}/sites`,
    DigitalReceiptTemplates: `${BASE_URL_DIGITAL_RECEIPT}/mandators`,
    DigitalReceiptsTemplates: `${BASE_URL_DIGITAL_RECEIPT}/templates`,
    DigitalReceiptHistory: `${BASE_URL_DIGITAL_RECEIPT}/history`,
    SiteAssignmentsTemplates: `${BASE_URL_DIGITAL_RECEIPT}/sites/templates/assignments`,
    ReceiptFields: `${BASE_URL_DIGITAL_RECEIPT}/fields`,
    MarketplaceModules: `${BASE_URL}/modules`,

    // FLEETNET SQUAD ROUTES
    CardIssuers: `${BASE_URL_FLEETNET}/card-issuer`,
    CardProfiles: `${BASE_URL_FLEETNET}/card-profiles`,
    CardProfilesValidate: `${BASE_URL_FLEETNET}/card-profiles/validate`,
    HostProvider: `${BASE_URL_FLEETNET}/host-provider`,
    ActiveCardProfile: `${BASE_URL_FLEETNET}/card-profiles/list`,
    ProductMapping: `${BASE_URL_FLEETNET}/product-mapping`,
    HostConfiguration: `${BASE_URL_FLEETNET}/host-configurations`,
    TestHostConfiguration: `${BASE_URL_FLEETNET}/test-host-connection`,
    Reconciliation: `${BASE_URL_FLEETNET}/reconcile-audits`,
    TrackDataField: `${BASE_URL_FLEETNET}/track-data-field/list`,
    CardType: `${BASE_URL_FLEETNET}/card-types/list`,
    Scheme: `${BASE_URL_FLEETNET}/schemes/list`,
    HostProviderList: `${BASE_URL_FLEETNET}/host-provider/list`,
    SiteConfiguration: `${BASE_URL_FLEETNET}/site-configurations`,
    DeviceConfiguration: `${BASE_URL_FLEETNET}/device-configurations`,

    // PARKING SQUAD ROUTES
    Tariffs: `${BASE_URL}/parking-tariffs`,
    SiteTariffAssignments: `${BASE_URL}/parking-tariffs/assignments`,
    FreeParkingBFF: `${BASE_URL_FREEPARKING}-bff`,
};

export const NOMINATIM_REVERSE_URL = (lat: number, lng: number) => {
    return `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json&accept-language=en`;
};

export const NOMINATIM_GEOCODE_URL = (address: string) => {
    return `https://nominatim.openstreetmap.org/search?q=${address}&format=json`;
};

export const RECEIPT_APP_URL = `https://${process.env.REACT_APP_DIGITAL_RECEIPT_BASE_URL}/transactions`;

export const PROMO_IMAGE = `${STORAGE_URL}/promo_logo.png`;

export const LOGO_IMAGE = `${STORAGE_URL}/receipt_logo.png`;

export const DEFAULT_LOGO_IMAGE = `${STORAGE_URL}/HecOne_logo.png`;

export const CITYLINE_LOGO = `${STORAGE_URL}/customization/CityLine.png`;
export const HECCHARGE_LOGO = `${STORAGE_URL}/customization/HecCharge.png`;
export const HECTRONIC_LOGO = `${STORAGE_URL}/customization/Hectronic.png`;

export const EMAIL_HECTRONIC_LOGO = `${STORAGE_URL}/email_hectronic_logo.png`;
