import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

import { closeModal } from '../../../helpers';

interface CheckboxConfirmationModalProps {
    message: string;
    component?: string;
    customCheckboxLabel?: string;
    onConfirm?: any;
}

/**
 * Renders a modal with a checkbox and confirm/cancel buttons.
 *
 * @param {CheckboxConfirmationModalProps} props - The props for the component.
 * @param {string} props.message - The message to display in the modal.
 * @param {string} [props.component] - The component name to display in the message.
 * @param {string} [props.customCheckboxLabel] - The label for the checkbox.
 * @param {function} props.onConfirm - The function to call when the confirm button is clicked.
 * @return {JSX.Element} The rendered modal.
 */
const CheckboxConfirmationModal = ({
    component,
    customCheckboxLabel,
    message,
    onConfirm,
}: CheckboxConfirmationModalProps): JSX.Element => {
    const { t } = useTranslation();
    const [checkboxValue, setCheckboxValue] = useState(false);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, pt: 2 }}>
            <Typography variant="h3" align="center">
                {t(message, { val: component })
                    .split('\n')
                    .map((i, key) => {
                        return (
                            <div key={key} className={`test ${key}`}>
                                {i}
                            </div>
                        );
                    })}
            </Typography>

            {customCheckboxLabel && (
                <FormControlLabel
                    value={checkboxValue}
                    control={<Checkbox />}
                    label={customCheckboxLabel}
                    onChange={() => setCheckboxValue(!checkboxValue)}
                />
            )}
            <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>
                <Button variant="outlined" onClick={closeModal} sx={{ mr: 1, px: 6 }}>
                    {t('general.labels.no')}
                </Button>

                <Button
                    variant="contained"
                    sx={{ px: 6 }}
                    onClick={
                        customCheckboxLabel ? () => onConfirm?.(checkboxValue) : () => onConfirm?.()
                    }>
                    {t('general.labels.yes')}
                </Button>
            </Box>
        </Box>
    );
};

export default CheckboxConfirmationModal;
