import { lookup } from 'country-data';
import moment from 'moment-timezone';

import {
    LANGUAGES,
    NumberSeparators,
    DAY_FORMAT,
    MONTH_FORMAT,
    YEAR_FORMAT,
    HOUR_FORMAT,
    MINUTES_FORMAT,
    DEFAULT_CURRENCY,
    DEFAULT_DATE,
} from '../constants';
import { NumberSeparatorsTypes } from '../app/common/interfaces';

/**
 * Converts 'active' or 'inactive' strings to boolean values.
 * @param enabled - one of 'active' or 'inactive'
 * @returns boolean value
 */
export const convertEnabledToBoolean = (enabled: string): boolean | undefined => {
    switch (enabled) {
        case 'active':
            return true;
        case 'inactive':
            return false;
        default:
            return undefined;
    }
};

/**
 * Converts country name to its ISO 3166-1 alpha-2 code.
 * Returns 'GB' if country name is not found.
 * @param countryName - country name in English
 * @returns ISO 3166-1 alpha-2 code of the country
 */
export const convertCountryNameToCode = (countryName: string): string => {
    const country = lookup.countries({ name: capitalizeString(countryName) })[0];
    return country ? country.alpha2 : 'GB';
};

/**
 * Returns a new string with the first character of the given string capitalized.
 * If the given string is empty, returns an empty string.
 * @param str - string to capitalize
 * @returns capitalized string
 */
export const capitalizeString = (str: string): string => {
    return str ? `${str.substring(0, 1).toUpperCase()}${str.slice(1)}` : '';
};

/**
 * Returns a language code with a country code.
 * If the language code is not found in the LANGUAGES list,
 * returns the language code with 'GB' as the country code.
 * @param lng - language code
 * @returns language code with country code
 */
export const addCountryCodeToLanguage = (lng: string): string => {
    const countryCode = LANGUAGES.find((language) => language.code === lng)?.countryCode;
    return `${lng}-${countryCode}` ?? 'en-GB';
};

/**
 * Returns a date format from a given date-time format.
 * If the given date-time format consists of more than 3 parts separated by a space,
 * the date format is the first three parts separated by a space.
 * Otherwise, the date format is the first part of the date-time format.
 * Commas are removed from the returned date format.
 * @param dateTimeFormat - date-time format
 * @returns date format
 */
const getDateFormatFromDateTimeFormat = (dateTimeFormat: string): string => {
    let dateFormat = '';
    const splittedDateTimeFormat = dateTimeFormat.split(' ');
    if (splittedDateTimeFormat.length > 3) {
        dateFormat =
            `${splittedDateTimeFormat[0]} ${splittedDateTimeFormat[1]} ${splittedDateTimeFormat[2]}`.replace(
                ',',
                ''
            );
    } else {
        dateFormat = splittedDateTimeFormat[0].replace(',', '');
    }
    return dateFormat;
};

/**
 * Converts an UTC hour to a local hour in a given language.
 * The UTC hour is expected to be in the 24-hour format (HH:mm).
 * The returned hour is in the 24-hour format (HH).
 * @param lang - language code
 * @param utcHour - UTC hour to be converted
 * @returns local hour
 */
export const convertHourToLocale = (lang: string, utcHour: string): number => {
    const countryCode = LANGUAGES.find((language) => language.code === lang)?.countryCode ?? 'GB';
    const timezone = moment.tz.zonesForCountry(countryCode)[0];
    const momentDateAndTime = moment.utc(`${DEFAULT_DATE} ${utcHour}`).tz(timezone);
    return Number(momentDateAndTime?.format().slice(11, 13));
};

/**
 * Returns formats for a given language code.
 * The returned formats are date format, date-time format, decimal separator, thousands separator,
 * moment date-time format and moment date format.
 * @param lng - language code
 * @returns formats object
 */
export const getFormats = (lng: string) => {
    const todayDate = Intl.DateTimeFormat(lng, {
        dateStyle: 'short',
        timeStyle: 'short',
    }).formatToParts(new Date());

    const dateFormat = todayDate.map((el) => {
        switch (el.type) {
            case 'year':
                return YEAR_FORMAT;
            case 'day':
                return DAY_FORMAT;
            case 'month':
                return MONTH_FORMAT;
            case 'hour':
                return HOUR_FORMAT;
            case 'minute':
                return MINUTES_FORMAT;
            case 'dayPeriod':
                return '';
            default:
                return el.value;
        }
    });

    const numberFormat = Intl.NumberFormat(lng).formatToParts(18988999999.2);
    const decimalSeparator =
        (numberFormat.find((item) => item.type === 'decimal')?.value as NumberSeparatorsTypes) ??
        '.';
    const thousandsSeparator =
        (numberFormat.find((item) => item.type === 'group')?.value as NumberSeparatorsTypes) ?? ',';

    const date = getDateFormatFromDateTimeFormat(dateFormat.join(''));

    return {
        dateFormat: date,
        dateTimeFormat: dateFormat.join(''),
        decimalSeparator: decimalSeparator,
        thousandsSeparator: thousandsSeparator,
        momentDateTimeFormat: dateFormat
            .join('')
            .replace(DAY_FORMAT, convertStringToUpperCase(DAY_FORMAT))
            .replace(YEAR_FORMAT, convertStringToUpperCase(YEAR_FORMAT)),
        momentDateFormat: convertStringToUpperCase(date),
    };
};

export const convertNumberToString = (
    data: number | string | null | undefined,
    language: string,
    options?: Intl.NumberFormatOptions
) => {
    if (typeof data === 'string') {
        return parseFloat(data).toLocaleString(language, options);
    } else {
        return data !== null && data !== undefined ? data?.toLocaleString(language, options) : '';
    }
};

export const convertStringToNumber = (
    number: string,
    decimalSeparator: string,
    thousandsSeparator: string
) => {
    let value = number;
    if (decimalSeparator === NumberSeparators.Comma) {
        if (thousandsSeparator === NumberSeparators.Point) {
            value = value.replaceAll(NumberSeparators.Point, '');
        }
        value = value.replace(NumberSeparators.Comma, NumberSeparators.Point);
    } else if (
        decimalSeparator === NumberSeparators.Point &&
        thousandsSeparator === NumberSeparators.Comma
    ) {
        value = value.replaceAll(NumberSeparators.Comma, '');
    }
    return parseFloat(value.replace(/\s/g, ''));
};

export const convertStringToUpperCase = (str: string) => {
    return str.toUpperCase();
};

export const convertNumberToCurrencyString = (
    data: number,
    language: string,
    currency = DEFAULT_CURRENCY,
    unitMeasure?: string
): string => {
    if (!unitMeasure) {
        return `${convertNumberToString(data, language)} ${currency}`;
    }
    return `${convertNumberToString(data, language)} ${currency}/${unitMeasure}`;
};

export const convertCoordinates = (lat: number, lng: number, language: string) => {
    return `${convertNumberToString(lat, language)}, ${convertNumberToString(lng, language)}`;
};

export const htmlEncode = (htmlString: string) => {
    return htmlString
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
};

export const htmlDecode = (str: string) => {
    if (str && typeof str === 'string') {
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
    }

    return str;
};
