import { useEffect, useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import { useAppSelector, useWindowSize } from '../../../hooks';

import { BREAKPOINTS } from '../../../../constants';
import { FormInput } from '../..';
import { convertNumberToString } from '../../../../helpers';
import { InputType } from '../../interfaces';

interface TextCellProps extends GridCellProps {
    component?: JSX.Element;
    inputType?: InputType;
    required?: boolean;
    minLimit?: number;
    maxLimit?: number;
    disabled?: boolean;
    numberFormat?: string;
    step?: number;
}
/**
 * Converts a string value to a number if it is a valid number, otherwise returns an empty string.
 *
 * @param {string} value - The string value to be converted.
 * @return {number|string} - The converted number if the input is a valid number, otherwise an empty string.
 */
const convertNumber = (value: string): number | string => {
    return isNaN(parseFloat(value)) ? '' : parseFloat(value);
};

/**
 * Renders a custom text cell for a Kendo React Grid.
 *
 * @param {TextCellProps} props - The props for the TextCell component.
 * @param {object} props.dataItem - The data item.
 * @param {string} props.dataIndex - The index of the data item.
 * @param {JSX.Element} [props.component] - The component to render.
 * @param {string} [props.field] - The name of the field which is bound to the cell.
 * @param {function} [props.onChange] - The function to call when the value changes.
 * @param {InputType} [props.inputType] - The type of the input.
 * @param {boolean} [props.required] - Whether the field is required.
 * @param {number} [props.minLimit] - The minimum value limit.
 * @param {number} [props.maxLimit] - The maximum value limit.
 * @param {boolean} [props.disabled] - Whether the input is disabled.
 * @param {string} [props.numberFormat] - The number format.
 * @param {number} [props.step] - The step value.
 * @param {function} [props.render] - The function to render the default rendering.
 * @param {string} [props.className] - Additional class name for the cell.
 * @param {React.CSSProperties} [props.style] - Additional inline styles for the cell.
 * @return {JSX.Element} The rendered custom cell.
 */
const TextCell = (props: TextCellProps) => {
    const {
        className,
        component,
        dataIndex,
        dataItem,
        disabled,
        field,
        inputType,
        maxLimit,
        minLimit,
        numberFormat,
        required,
        step,
        style,
        onChange,
        render,
    } = props;
    const { ISOLanguageCode } = useAppSelector((state) => state.commonData);
    const windowDimensions = useWindowSize();
    const isMobileSize = windowDimensions.width <= BREAKPOINTS.xs;

    const fieldName = field || '';
    const isRequired = required && !dataItem[fieldName];
    const [inputValue, setInputValue] = useState(dataItem[fieldName]);

    const handleChange = (e: any) => {
        const newValue = inputType === 'number' ? convertNumber(e.target.value) : e.target.value;
        if (onChange) {
            onChange({
                dataItem,
                dataIndex,
                syntheticEvent: e.syntheticEvent,
                field: fieldName,
                value: newValue,
            });
            setInputValue(newValue);
        }
    };
    const errors = isRequired ? { [fieldName]: 'required' } : {};
    const touched = isRequired ? { [fieldName]: true } : { [fieldName]: false };

    useEffect(() => {
        setInputValue(dataItem[fieldName]);
    }, [dataItem, fieldName]);

    const defaultRendering = (
        <td
            className={`custom-column ${isMobileSize ? 'text-center' : ''} ${className}`}
            style={style}>
            {dataItem.inEdit && inputType ? (
                <FormInput
                    value={inputValue}
                    handleChange={handleChange}
                    fieldName={fieldName}
                    type={inputType}
                    errors={errors}
                    touched={touched}
                    className="editableGridCell"
                    minLimit={minLimit}
                    maxLimit={maxLimit}
                    disabled={disabled}
                    numberFormat={numberFormat ?? 'n0'}
                    step={step}
                />
            ) : (
                <>
                    <span>
                        {inputType === 'number'
                            ? convertNumberToString(inputValue, ISOLanguageCode)
                            : inputValue}
                    </span>
                    {component}
                </>
            )}
        </td>
    );
    return render ? render(defaultRendering, props) : defaultRendering;
};

export default TextCell;
