import { createRef } from 'react';
import {
    Upload,
    ExternalDropZone,
    UploadFileInfo,
    UploadOnStatusChangeEvent,
    UploadOnRemoveEvent,
    UploadOnAddEvent,
    UploadOnProgressEvent,
} from '@progress/kendo-react-upload';

import { useAppDispatch } from '../hooks';
import { setLoading } from './loading/loadingDuck';

import { ImageMetaData } from './interfaces';
import Interceptor from './Interceptor';

// Kendo upload component attributes meaning
// autoUpload - true by default -> the selected files are immediately uploaded
// batch - true by default -> all files in the selection are uploaded in one request
// multiple - false by default -> enables selection of multiple files
// withCredentials - true by default -> allows credentials to be sent for cross-site requests; has no effect on same-site req

interface UploadFilesProps {
    saveUrl: string;
    removeUrl: string | null;

    allowedExtensions?: string[];
    autoUpload?: boolean;
    defaultFiles?: UploadFileInfo[];
    defaultImage?: string;
    externalDropZoneCustomHint?: string;
    externalDropZoneCustomNote?: string;
    fieldName?: string;
    fileMetaData?: ImageMetaData;
    files?: UploadFileInfo[];
    isDisabled?: boolean;
    multipleFiles?: boolean;
    updateStateVariable?: any;
    useExternalDropZone?: boolean;
    withCredentials?: boolean;
    onAdd?: (data: UploadOnAddEvent) => void;
    onChange?: (data: UploadOnStatusChangeEvent) => void;
    onFileProgress?: (data: UploadOnProgressEvent) => void;
    onRemove?: (data: UploadOnRemoveEvent) => void;
    setFieldValue?: (field: string, value: any) => void;
}

/**
 * React component for uploading files with various options and callbacks.
 *
 * @param  {UploadFilesProps} props - The props for the UploadFiles component.
 * @param {string} props.saveUrl - The URL where files will be saved.
 * @param {string} props.removeUrl - The URL where files will be removed.
 * @param {string[]} props.allowedExtensions - An array of allowed file extensions.
 * @param {UploadFileInfo[]} props.defaultFiles - Default files to be displayed.
 * @param {boolean} props.multipleFiles - Flag indicating if multiple files can be uploaded.
 * @param {boolean} props.isDisabled - Flag indicating if the component is disabled.
 * @param {boolean} props.autoUpload - Flag indicating if files are auto-uploaded.
 * @param {boolean} props.useExternalDropZone - Flag indicating if an external drop zone is used.
 * @param {string} props.externalDropZoneCustomHint - Custom hint for the external drop zone.
 * @param {string} props.externalDropZoneCustomNote - Custom note for the external drop zone.
 * @param {boolean} props.withCredentials - Flag indicating if credentials are sent with requests.
 * @param {ImageMetaData} props.fileMetaData - Metadata for image files.
 * @param {UploadFileInfo[]} props.files - The uploaded files.
 * @param {(data: UploadOnStatusChangeEvent) => void} props.onChange - Callback for status change.
 * @param {(data: UploadOnRemoveEvent) => void} props.onRemove - Callback for file removal.
 * @param {(data: UploadOnAddEvent) => void} props.onAdd - Callback for file addition.
 * @param {(data: UploadOnProgressEvent) => void} props.onFileProgress - Callback for file upload progress.
 * @param {(field: string, value: any) => void} props.setFieldValue - Function to set field value.
 * @param {string} props.fieldName - The name of the field.
 * @param {string} props.defaultImage - Default image to display.
 * @param {any} props.updateStateVariable - Function to update state variable.
 * @return {JSX.Element} The UploadFiles component.
 */
const UploadFiles = ({
    allowedExtensions,
    autoUpload,
    defaultFiles,
    defaultImage,
    externalDropZoneCustomHint,
    externalDropZoneCustomNote,
    fieldName,
    fileMetaData,
    files,
    isDisabled,
    multipleFiles,
    removeUrl,
    saveUrl,
    updateStateVariable,
    useExternalDropZone,
    withCredentials,
    onAdd,
    onChange,
    onFileProgress,
    onRemove,
    setFieldValue,
}: UploadFilesProps): JSX.Element => {
    const uploadRef = createRef<Upload>();
    const dispatch = useAppDispatch();

    const defaultFilesData = files?.length === 0 || files === undefined ? defaultFiles : undefined;

    const handleUploadRequest = (
        currentFiles: UploadFileInfo[],
        options: { formData: FormData; requestOptions: any },
        onProgress: (uid: string, event: ProgressEvent) => void
    ) => {
        const uid = currentFiles[0].uid;
        dispatch(setLoading(true));
        return new Promise<{ uid: string }>((resolve) => {
            Interceptor()
                .post(saveUrl, options.formData, {
                    onUploadProgress: (event) =>
                        onProgress(uid, event as unknown as ProgressEvent<EventTarget>),
                })
                .then((event: any) => {
                    uploadRef.current?.onUploadSuccess(uid, event);
                    resolve({ uid });
                    const newFile = event?.data?.data?.[0]?.access_file_path ?? defaultImage;
                    setFieldValue?.(fieldName ?? '', newFile);
                    updateStateVariable && dispatch(updateStateVariable(newFile));
                })
                .catch((event) => uploadRef.current?.onUploadError(uid, event))
                .finally(() => dispatch(setLoading(false)));
        });
    };
    const handleRemoveRequest = (currentFiles: UploadFileInfo[]) => {
        const uid = currentFiles[0].uid;
        if (removeUrl) {
            return new Promise<{ uid: string }>((resolve) => {
                Interceptor()
                    .delete(removeUrl)
                    .then((event: any) => {
                        uploadRef.current?.onRemoveSuccess(uid, event);
                        resolve({ uid });
                    })
                    .catch((event) => uploadRef.current?.onRemoveError(uid, event));
            });
        } else {
            return Promise.resolve({ uid });
        }
    };

    return (
        <>
            <Upload
                ref={uploadRef}
                restrictions={{
                    maxFileSize: fileMetaData?.maxSize,
                    allowedExtensions: allowedExtensions,
                }}
                multiple={multipleFiles ?? false}
                defaultFiles={defaultFilesData}
                saveUrl={handleUploadRequest}
                removeUrl={handleRemoveRequest}
                disabled={isDisabled}
                autoUpload={autoUpload}
                withCredentials={withCredentials}
                onStatusChange={onChange}
                onRemove={onRemove}
                onAdd={onAdd}
                onProgress={onFileProgress}
                files={files}
            />

            {useExternalDropZone ? (
                <ExternalDropZone
                    uploadRef={uploadRef}
                    customHint={externalDropZoneCustomHint}
                    customNote={externalDropZoneCustomNote}
                    disabled={isDisabled}
                />
            ) : null}
        </>
    );
};

export default UploadFiles;
