import { Dispatch, SetStateAction } from 'react';
import { getModuleById } from '../app/pages/marketplace/marketplaceDuck';
import { Module } from '../app/pages/interfaces';

export const getServiceStatus = async (moduleId: string, dispatch: (action: any) => any) => {
    const response = await dispatch(getModuleById(moduleId));
    return response.payload.active;
};

export const setExtendedModuleInfo = async (
    item_id: string,
    dispatch: (action: any) => any,
    setModule?: Dispatch<SetStateAction<Module | undefined>>,
    setModuleStatus?: Dispatch<SetStateAction<boolean>>
) => {
    // get module info
    const response = await dispatch(getModuleById(item_id));
    const moduleData = (await response).payload;
    setModule && setModule(moduleData);
    setModuleStatus && setModuleStatus(response?.data?.active);
};

export const getActiveModulesForMandator = (modules: Module[]) => {
    return modules
        .filter((module) => module.active)
        .map((item) => {
            return item.service_id;
        });
};
