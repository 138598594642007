import { ChangeEvent, FocusEventHandler } from 'react';
import {
    FormControlLabel,
    Switch,
    Typography,
    FormControlLabelProps,
    TypographyProps,
    SwitchProps,
    Checkbox,
    Radio,
    SxProps,
} from '@mui/material';

interface FormControlLabelComponentProps {
    color: SwitchProps['color'];
    name: string;

    active?: boolean;
    checkedRadioButton?: boolean;
    control?: string;
    disabled?: boolean;
    label?: string;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    labelVariant?: TypographyProps['variant'];
    size?: SwitchProps['size'];
    sx?: SxProps;
    value?: boolean | string;
    onBlur?: FocusEventHandler<HTMLButtonElement>;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Renders a form control label component based on the provided props.
 *
 * @param {FormControlLabelComponentProps} props - The props for the form control label component.
 * @param {boolean} props.active - Whether the control is active.
 * @param {string} props.name - The name of the control.
 * @param {(e: ChangeEvent<HTMLInputElement>) => void} props.onChange - The function to be called when the control value changes.
 * @param {FocusEventHandler<HTMLButtonElement>} props.onBlur - The function to be called when the control loses focus.
 * @param {string} [props.label] - The label text.
 * @param {'end' | 'start' | 'top' | 'bottom'} [props.labelPlacement] - The placement of the label.
 * @param {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'overline' | undefined} [props.labelVariant] - The variant of the label.
 * @param {'small' | 'medium'} [props.size] - The size of the control.
 * @param {'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'} props.color - The color of the control.
 * @param {'switch' | 'checkbox' | 'radio'} [props.control] - The type of the control.
 * @param {SxProps} [props.sx] - The CSS styles for the component.
 * @param {boolean | string} [props.value] - The value of the control.
 * @param {boolean} [props.disabled] - Whether the control is disabled.
 * @param {boolean} [props.checkedRadioButton] - Whether the radio button is checked.
 * @return {JSX.Element} The rendered form control label component.
 */
const FormControlLabelComponent = ({
    active,
    checkedRadioButton,
    color,
    control,
    disabled,
    label,
    labelPlacement,
    labelVariant,
    name,
    size,
    sx,
    value,
    onBlur,
    onChange,
}: FormControlLabelComponentProps): JSX.Element => {
    const renderControl = () => {
        switch (control) {
            case 'switch':
                return (
                    <Switch
                        checked={active}
                        onChange={onChange}
                        onBlur={onBlur}
                        size={size}
                        color={color}
                        name={name}
                        disabled={disabled}
                        role={name}
                    />
                );

            case 'checkbox':
                return (
                    <Checkbox
                        name={name}
                        checked={active}
                        onChange={onChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color={color}
                        disabled={disabled}
                        role={name}
                    />
                );

            default:
                return (
                    <Radio
                        color={color}
                        size={size}
                        role={name}
                        onChange={onChange}
                        checked={checkedRadioButton}
                    />
                );
        }
    };

    const renderLabel = (text: string) => {
        return <Typography variant={labelVariant}>{text}</Typography>;
    };

    return (
        <FormControlLabel
            value={value}
            control={renderControl()}
            label={label && renderLabel(label)}
            labelPlacement={labelPlacement}
            sx={sx}
            disabled={disabled}
            role={control}
        />
    );
};
export default FormControlLabelComponent;
