import { FocusEvent, SyntheticEvent } from 'react';
import { FormControl, Typography, Autocomplete, TextField, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { InfoRounded } from '@mui/icons-material';
import TooltipButton from '../buttons/TooltipButton';

interface AutoCompleteSelectProps {
    data: Record<string, any>[];
    titleEntry: string;
    onChange: (e: SyntheticEvent<Element, Event>, value?: any) => void;

    className?: string;
    disabled?: boolean;
    errors?: FormikErrors<FormikValues>;
    hideErrors?: boolean;
    infoLabel?: string;
    label?: string;
    name?: string;
    placeHolder?: string;
    sx?: SxProps;
    tooltip?: string;
    touched?: FormikTouched<FormikValues>;
    value?: string | number | Record<string, any> | null;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

/**
 * Renders an autocomplete select component with tooltip, label, error handling, and placeholder.
 *
 * @param {Record<string, any>[]} props.data - The data for the autocomplete select.
 * @param {(e: SyntheticEvent<Element, Event>, value?: any) => void} props.onChange - The change event handler.
 * @param {(e: FocusEvent<HTMLInputElement>) => void} [props.onBlur] - The blur event handler.
 * @param {string} [props.name] - The name of the autocomplete select.
 * @param {string} props.titleEntry - The title entry for the autocomplete select.
 * @param {string | number | Record<string, any> | null} [props.value] - The value of the autocomplete select.
 * @param {string} [props.tooltip] - The tooltip for the autocomplete select.
 * @param {boolean} [props.disabled] - Flag indicating if the autocomplete select is disabled.
 * @param {FormikErrors<FormikValues>} [props.errors] - The errors for the autocomplete select.
 * @param {string} [props.className] - The CSS class name for the autocomplete select.
 * @param {boolean} [props.hideErrors] - Flag indicating if errors should be hidden.
 * @param {string} [props.placeHolder] - The placeholder for the autocomplete select.
 * @param {FormikTouched<FormikValues>} [props.touched] - The touched state for the autocomplete select.
 * @param {SxProps} [props.sx] - The custom CSS styling for the autocomplete select.
 * @param {string} [props.label] - The label for the autocomplete select.
 * @param {string} [props.infoLabel] - The information label for the autocomplete select.
 * @returns {JSX.Element} The rendered autocomplete select component.
 */
const AutocompleteSelect = ({
    className,
    data,
    disabled,
    errors,
    hideErrors,
    infoLabel,
    label,
    name,
    placeHolder,
    sx,
    titleEntry,
    tooltip,
    touched,
    value,
    onBlur,
    onChange,
}: AutoCompleteSelectProps): JSX.Element => {
    const fieldName = name ?? '';
    const applyError = touched?.[fieldName] && errors?.[fieldName] ? true : false;
    const errorMessage = applyError ? errors?.[fieldName] : '';

    return (
        <Tooltip placement="bottom" title={tooltip || ''}>
            <FormControl className={className} variant="outlined" size="small" sx={sx}>
                {label && (
                    <Typography variant="h3">
                        {label}
                        {infoLabel && (
                            <TooltipButton
                                sx={{ py: 0, px: 1 }}
                                role="info-button"
                                title={infoLabel}
                                icon={<InfoRounded />}
                            />
                        )}
                    </Typography>
                )}

                <Autocomplete
                    sx={sx}
                    className={className}
                    disablePortal
                    onChange={onChange}
                    disableClearable
                    getOptionLabel={(option) => option[titleEntry]}
                    options={data}
                    onBlur={onBlur}
                    disabled={disabled}
                    value={typeof value === 'object' ? value : null}
                    role={fieldName}
                    id={fieldName}
                    renderInput={(params) => (
                        <TextField {...params} error={applyError} placeholder={placeHolder} />
                    )}
                />

                {errors && !hideErrors && (
                    <Typography
                        color="#d63232"
                        variant="caption"
                        className="errorHeight"
                        role={`error-${fieldName}`}>
                        <>{errorMessage}</>
                    </Typography>
                )}
            </FormControl>
        </Tooltip>
    );
};

export default AutocompleteSelect;
