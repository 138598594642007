import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, SelectChangeEvent } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';

import { useAppSelector, useDownloadFile } from '../../hooks';

import { FormInput, Interceptor, SelectComponent } from '..';
import { FileExtension } from '../../pages/interfaces';
import { getFileName, showToaster } from '../../../helpers';
import { ToasterType } from '../../../constants';

interface DownloadModalProps {
    formInitialValues: FormikValues;
    mandatorName: string;
    selectOptions: Record<string, string>[];
    handleModalClose(): void;

    formValidations?: Yup.ObjectSchema<FormikValues>;
    getData?: (fields: FormikValues | string) => void | Promise<any>;
}

/**
 * Renders a download modal component.
 *
 * @param {DownloadModalProps} props - The props for the component.
 * @param {Record<string, string>[]} props.selectOptions - The options for the select component.
 * @param {FormikValues} props.formInitialValues - The initial values for the form.
 * @param {string} props.mandatorName - The name of the mandator.
 * @param {() => void} props.handleModalClose - The function to close the modal.
 * @param {((fields: FormikValues | string) => void | Promise<any>)?} props.getData - The function to get data.
 * @param {Yup.ObjectSchema<FormikValues>?} props.formValidations - The validation schema for the form.
 * @return {JSX.Element} The rendered download modal component.
 */
const DownloadModal = ({
    formInitialValues,
    formValidations,
    mandatorName,
    selectOptions,
    getData,
    handleModalClose,
}: DownloadModalProps): JSX.Element => {
    const { t } = useTranslation();
    const { formats } = useAppSelector((state) => state.commonData);
    const [selectedExportFormat, setSelectedExportFormat] = useState<`${FileExtension}`>(
        FileExtension.CSV
    );

    const handleSubmit = async (fields: FormikValues) => {
        handleModalClose();
        if (!fields.email) {
            download();
        } else {
            await getData?.(fields);
            showToaster(ToasterType.Success, t('general.messages.successfulSentEmail'));
        }
    };

    const getFile = useCallback(async () => {
        const response = await getData?.({ option: selectedExportFormat });
        // get file content
        return Interceptor().get(response, { responseType: 'blob' });
    }, [getData, selectedExportFormat]);

    const onErrorDownloadFile = () => {
        showToaster(ToasterType.Error, t('general.messages.downloadFailed'));
    };
    const { download } = useDownloadFile({
        apiDefinition: getFile,
        onError: onErrorDownloadFile,
        fileName: getFileName(formats.momentDateTimeFormat, selectedExportFormat, mandatorName),
    });

    return (
        <Formik
            initialValues={formInitialValues}
            onSubmit={handleSubmit}
            validationSchema={formValidations}>
            {({ values, handleChange, touched, errors, handleBlur }) => (
                <Form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '16px',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                    }}>
                    <SelectComponent
                        data={selectOptions}
                        defaultValue={values.option}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                            setSelectedExportFormat(e.target.value as FileExtension);
                            handleChange(e);
                        }}
                        valueEntry="value"
                        titleEntry="name"
                        name="option"
                        formChild
                        label={t('general.labels.exportSelect')}
                        touched={touched}
                        className="formControl"
                    />
                    <FormInput
                        label={t('general.labels.receiverEmail')}
                        fieldName="email"
                        value={values.email}
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        placeholder="username@hecone.com"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="outlined"
                            onClick={handleModalClose}
                            sx={{
                                mr: 1,
                            }}>
                            {t('general.labels.cancel')}
                        </Button>

                        <Button type="submit" variant="contained">
                            {t('general.labels.download')}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default DownloadModal;
