import { Dispatch, SetStateAction } from 'react';
import i18n from '../i18n';

import { displayModal } from '../app/common/modal/modalDuck';

import {
    BulkActionsOptions,
    MODAL_TYPES,
    Operations,
    SUCCESSFUL_SELECTED_ACTION,
    ToasterType,
} from '../constants';
import { closeModal, showToaster } from '.';

interface DeleteEntities {
    component: string;
    deleteEntities: any;
    selectedEntities: any;
    dispatch: (action: any) => any;
    getData: () => void;
    isDeleteAllowed?: boolean;
    message?: string;
    setSelectedEntitiesIds?: Dispatch<SetStateAction<any>>;
}

const onDeleteEntities = async (
    selectedEntities: any,
    deleteEntities: any,
    dispatch: (action: any) => any,
    getData: () => void,
    setSelectedEntitiesIds?: Dispatch<SetStateAction<any>>
) => {
    closeModal();
    let entitiesToDelete = selectedEntities;
    if (typeof selectedEntities[0] !== 'string') {
        entitiesToDelete = {
            ids: selectedEntities.map((selectedEntity: any) => selectedEntity.id),
            action: BulkActionsOptions.Delete,
        };
    }
    const response = await dispatch(deleteEntities(entitiesToDelete));

    if (deleteEntities.fulfilled.match(response)) {
        showToaster(ToasterType.Success, SUCCESSFUL_SELECTED_ACTION, Operations.Deleted);
        getData();
        setSelectedEntitiesIds?.([]);
    }
};

export const handleDeleteEntities = ({
    component,
    deleteEntities,
    selectedEntities,
    dispatch,
    getData,
    message = 'general.messages.deleteConfirmation',
    isDeleteAllowed = true,
    setSelectedEntitiesIds,
}: DeleteEntities): void => {
    const modalTitle = i18n.t(`general.labels.modalHeaders.${component}`);
    dispatch(
        displayModal({
            showModal: true,
            title: modalTitle,
            type: MODAL_TYPES.ConfirmationMessage,
            message,
            component,
            onLeave: () => {
                isDeleteAllowed
                    ? onDeleteEntities(
                          selectedEntities,
                          deleteEntities,
                          dispatch,
                          getData,
                          setSelectedEntitiesIds
                      )
                    : closeModal();
            },
            ...(!isDeleteAllowed
                ? {
                      confirmCustomLabel: i18n.t('general.labels.ok'),
                      isCancelButtonHidden: true,
                  }
                : {}),
        })
    );
};
