import { lazy } from 'react';

// lazy loaded components
// CORE COMPONENTS
export const CardDetails = lazy(() => import('./cards/CardDetails'));
export const Cards = lazy(() => import('./cards/Cards'));
export const ChargingStationDetails = lazy(() => import('./manufacturers/ChargingStationDetails'));
export const ChargingStations = lazy(() => import('./manufacturers/ChargingStations'));
export const CustomizeChart = lazy(() => import('./dashboard/CustomizeChart'));
export const Dashboard = lazy(() => import('./dashboard/Dashboard'));
export const DashboardLayouts = lazy(() => import('./dashboard/layouts/DashboardLayouts'));
export const DeviceDetails = lazy(() => import('./devices/DeviceDetails'));
export const Devices = lazy(() => import('./devices/Devices'));
export const DigitalReceipts = lazy(
    () => import('./digitalReceipts/receiptTemplates/DigitalReceipts')
);
export const DigitalReceiptDetails = lazy(
    () => import('./digitalReceipts/receiptTemplates/DigitalReceiptDetails')
);
export const EmailDelivery = lazy(() => import('./digitalReceipts/emailDelivery/EmailDelivery'));
export const EditTranslation = lazy(
    () => import('./digitalReceipts/receiptTemplates/EditTranslation')
);
export const MandatorDetails = lazy(() => import('./mandators/MandatorDetails'));
export const Mandators = lazy(() => import('./mandators/Mandators'));
export const Marketplace = lazy(() => import('./marketplace/Marketplace'));
export const PointDetails = lazy(() => import('./points/PointDetails'));
export const Points = lazy(() => import('./points/Points'));
export const Prices = lazy(() => import('./prices/Prices'));
export const PricesDetails = lazy(() => import('./prices/PricesDetails'));
export const ProductDetails = lazy(() => import('./products/ProductDetails'));
export const Products = lazy(() => import('./products/Products'));
export const ProductsAssignment = lazy(() => import('./productsAssignment/ProductsAssignment'));
export const ProductAssignmentDetails = lazy(
    () => import('./productsAssignment/ProductsAssignmentDetails')
);
export const ProviderFieldDetails = lazy(() => import('./providerFields/ProviderFieldDetails'));
export const ProviderFields = lazy(() => import('./providerFields/ProviderFields'));
export const ProviderTemplates = lazy(() => import('./providerTemplates/ProviderTemplates'));
export const ProviderTemplateDetails = lazy(
    () => import('./providerTemplates/ProviderTemplateDetails')
);
export const SiteDetails = lazy(() => import('./sites/SiteDetails'));
export const Transactions = lazy(() => import('./transactions/Transactions'));
export const SiteAssignments = lazy(
    () => import('./digitalReceipts/siteAssignments/SiteAssignments')
);
export const SiteAssignmentDetails = lazy(
    () => import('./digitalReceipts/siteAssignments/SiteAssignmentDetails')
);
export const Sites = lazy(() => import('./sites/Sites'));
export const TwintDetails = lazy(() => import('./twint/TwintDetails'));
export const TwintSites = lazy(() => import('./twint/TwintSites'));
export const UserDetails = lazy(() => import('./users/UserDetails'));
export const UserPermissionDetails = lazy(() => import('./userPermissions/UserPermissionDetails'));
export const UserPermissions = lazy(() => import('./userPermissions/UserPermissions'));
export const Users = lazy(() => import('./users/Users'));

// PARKING COMPONENTS
export const Tariffs = lazy(() => import('./parking/tariffs/Tariffs'));
export const TariffDetails = lazy(() => import('./parking/tariffs/TariffDetails'));
export const TariffAssignments = lazy(() => import('./parking/siteAssignments/TariffAssignments'));
export const Configurations = lazy(() => import('./parking/freeParking/Configurations'));
export const ConfigurationDetails = lazy(
    () => import('./parking/freeParking/ConfigurationDetails')
);
export const ConfigurationAssignments = lazy(
    () => import('./parking/freeParking/siteAssignments/ConfigurationAssignments')
);
export const ConfigurationAssignmentDetails = lazy(
    () => import('./parking/freeParking/siteAssignments/ConfigurationAssignmentDetails')
);

// child components
export { default as AssignmentConfiguration } from './parking/siteAssignments/AssignmentConfiguration';
export { default as TransactionsChart } from './transactions/TransactionsChart';
export { PointProducts } from './productsAssignment/PointProducts';
export { ProviderTemplateField } from './providerTemplates/ProviderTemplateField';
