import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@mui/material';

interface TableComponentProps<T> {
    className?: string;
    columns: string[];
    columnsNames: string[];
    data: T[];
}

/**
 * Renders a MUI table component with the given columns names, data, and optional class name.
 *
 * @param {TableComponentProps<T>} props - The props object containing the columns names, data, and optional class name.
 * @param {string[]} props.columnsNames - An array of strings representing the column names.
 * @param {T[]} props.data - An array of objects representing the table data.
 * @param {string[]} props.columns - An array of strings representing the columns to display in the table.
 * @param {string} [props.className=''] - An optional class name for the table component.
 * @return {JSX.Element} The rendered table component.
 */
const TableComponent = <T extends Record<never, never>>({
    columnsNames,
    data,
    columns,
    className = '',
}: TableComponentProps<T>): JSX.Element => {
    return (
        <Table aria-label="table" className={className}>
            <TableHead>
                <TableRow>
                    {columnsNames.map((column: string, index: number) => (
                        <TableCell key={index}>
                            <Typography variant="subtitle2" mb={0}>
                                {column}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row, index) => (
                    <TableRow key={index}>
                        {columns.map((col, colIndex) => (
                            <TableCell key={colIndex}>
                                <Typography variant="h5" mb={0}>
                                    <>{row[col as keyof typeof row]}</>
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
export default TableComponent;
