import { ActionTypes, Actions, MarketplaceModuleEntity } from '../../constants';
import {
    // CORE
    CardDetails,
    Cards,
    ChargingStationDetails,
    ChargingStations,
    CustomizeChart,
    Dashboard,
    DashboardLayouts,
    DeviceDetails,
    Devices,
    DigitalReceiptDetails,
    DigitalReceipts,
    EditTranslation,
    EmailDelivery,
    MandatorDetails,
    Mandators,
    Marketplace,
    PointDetails,
    Points,
    Prices,
    PricesDetails,
    ProductAssignmentDetails,
    ProductDetails,
    Products,
    ProductsAssignment,
    ProviderFieldDetails,
    ProviderFields,
    ProviderTemplateDetails,
    ProviderTemplates,
    SiteAssignmentDetails,
    SiteAssignments,
    SiteDetails,
    Sites,
    Transactions,
    TwintDetails,
    TwintSites,
    UserDetails,
    UserPermissionDetails,
    UserPermissions,
    Users,
    // PARKING
    ConfigurationAssignmentDetails,
    ConfigurationAssignments,
    ConfigurationDetails,
    Configurations,
    TariffAssignments,
    TariffDetails,
    Tariffs,
    // END PARKING
} from '../pages';

import {
    AddCardProfile,
    AddHostProvider,
    CardProfileDetails,
    CardProfileList,
    HostProviderDetails,
    HostProvidersList,
} from '../fleetnet-router/pages';
import CardIssuerEditView from '../fleetnet-router/pages/cardIssuer/CardIssuerEditView';
import ModuleDetails from '../pages/marketplace/module/ModuleDetails';
import FleetList from '../fleetnet-router/pages/Fleetnet/FleetList';
import FleetnetSiteDetails from '../fleetnet-router/pages/FleetnetSites/FleetnetSiteDetails';
import FleetnetDeviceDetails from '../fleetnet-router/pages/FleetnetDevices/FleetnetDeviceDetails';
import FleetnetDevicesList from '../fleetnet-router/pages/FleetnetDevices/FleetnetDevicesList';
import FleetnetSitesList from '../fleetnet-router/pages/FleetnetSites/FleetnetSitesList';

interface PrivateRouteChild {
    component: JSX.Element;
    path: string;

    allowedActions?: ActionTypes[];
}
interface PrivateRoutes extends PrivateRouteChild {
    children?: PrivateRouteChild[];
    payed?: boolean;
    userRight?: string;
}

const generalAccess = [Actions.Edit, Actions.View];

export const AppPrivateRoutes: PrivateRoutes[] = [
    /* CORE SQUAD ROUTES */
    {
        path: '/dashboard',
        component: <Dashboard />,
        userRight: 'services.dashboard.demoChart',
        children: [
            {
                path: 'customize-chart',
                component: <CustomizeChart />,
            },
            {
                path: 'layouts',
                component: <DashboardLayouts />,
            },
        ],
    },
    {
        path: '/transactions',
        component: <Transactions />,
        userRight: 'services.manageTransactions.transactions',
    },
    /* Users routes following  */
    {
        path: '/users',
        component: <Users />,
        userRight: 'services.manageUsers.users',
        children: [
            {
                path: 'add',
                component: <UserDetails />,
            },
            {
                path: ':id/:action',
                component: <UserDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    /* Mandators routes following  */
    {
        path: '/mandators',
        component: <Mandators />,
        userRight: 'services.manageMandators.mandators',
        children: [
            {
                path: 'add',
                component: <MandatorDetails />,
            },
            {
                path: ':id/:action',
                component: <MandatorDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    /* Sites routes following  */
    {
        path: '/sites',
        component: <Sites />,
        userRight: 'services.manageSites.sites',
        children: [
            {
                path: 'add',
                component: <SiteDetails />,
            },
            {
                path: ':id/:action',
                component: <SiteDetails />,
                allowedActions: [...generalAccess, Actions.Copy],
            },
            {
                path: ':id/prices/:action',
                component: <PricesDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    /* Devices routes following  */
    {
        path: '/devices',
        component: <Devices />,
        userRight: 'services.manageSites.devices',
        children: [
            {
                path: 'add',
                component: <DeviceDetails />,
            },
            {
                path: ':id/:action',
                component: <DeviceDetails />,
                allowedActions: [...generalAccess, Actions.Copy],
            },
        ],
    },
    /* Points routes following  */
    {
        path: '/points',
        component: <Points />,
        userRight: 'services.manageSites.points',
        children: [
            {
                path: 'add',
                component: <PointDetails />,
            },
            {
                path: ':id/:action',
                component: <PointDetails />,
                allowedActions: [...generalAccess, Actions.Copy],
            },
        ],
    },
    /* Product management routes following  */
    {
        path: '/products',
        component: <Products />,
        userRight: 'services.manageProducts.products',
        children: [
            {
                path: 'add',
                component: <ProductDetails />,
            },
            {
                path: ':id/:action',
                component: <ProductDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    {
        path: '/products-assignment',
        component: <ProductsAssignment />,
        userRight: 'services.manageSites.productsAssignment',
        children: [
            {
                path: 'edit',
                component: <ProductAssignmentDetails />,
            },
        ],
    },
    /* Prices routes following  */
    {
        path: '/prices',
        component: <Prices />,
        userRight: 'services.manageSites.prices',
    },
    /* User-permissions routes following  */
    {
        path: '/user-permissions',
        component: <UserPermissions />,
        userRight: 'services.manageUserPermissions.userPermissions',
        children: [
            {
                path: 'add',
                component: <UserPermissionDetails />,
            },
            {
                path: ':id/:action',
                component: <UserPermissionDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    /* Charging stations routes following  */
    {
        path: '/charging-stations',
        component: <ChargingStations />,
        userRight: 'services.manageManufacturers.manufacturers',
        children: [
            {
                path: 'add',
                component: <ChargingStationDetails />,
            },
            {
                path: ':id/:action',
                component: <ChargingStationDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    /* Manage Marketplace routes following  */
    {
        path: '/manage-marketplace',
        component: <Marketplace entityName={MarketplaceModuleEntity.ControlTower} />,
        userRight: 'services.manageMarketplace.manageMarketplace',
        children: [],
    },
    /* Marketplace routes following  */
    {
        path: '/marketplace',
        component: <Marketplace entityName={MarketplaceModuleEntity.Administration} />,
        userRight: 'services.marketplace.marketplace',
        children: [
            {
                path: 'modules/:id',
                component: <ModuleDetails />,
            },
        ],
    },
    /* Digital Receipts routes following  */
    {
        path: '/digital-receipts',
        component: <DigitalReceipts />,
        userRight: 'services.manageDigitalReceipts.digitalReceipt',
        children: [
            {
                path: 'add',
                component: <DigitalReceiptDetails />,
            },
            {
                path: ':id/:action/',
                component: <DigitalReceiptDetails />,
                allowedActions: generalAccess,
            },
            {
                path: 'edit-translation',
                component: <EditTranslation />,
            },
        ],
    },
    {
        path: '/receipt-site-assignments',
        component: <SiteAssignments />,
        userRight: 'services.manageDigitalReceipts.siteAssignments',
        children: [
            {
                path: ':id/:action',
                component: <SiteAssignmentDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    {
        path: '/receipt-email-delivery',
        component: <EmailDelivery />,
        userRight: 'services.manageDigitalReceipts.emailTemplates',
    },
    /* Card routes following  */
    {
        path: '/cards',
        component: <Cards />,
        userRight: 'services.manageCards.cards',
        children: [
            {
                path: 'add',
                component: <CardDetails />,
            },
            {
                path: ':id/:action',
                component: <CardDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    /* Twint routes following  */
    {
        path: '/twint',
        component: <TwintSites />,
        userRight: 'services.manageTwint.twint',
        children: [
            {
                path: 'sites/:id/:action',
                component: <TwintDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    /* 3rd party/Providers routes following  */
    {
        path: '/provider-templates',
        component: <ProviderTemplates />,
        userRight: 'services.manageProviderTemplates.providerTemplates',
        children: [
            {
                path: 'add',
                component: <ProviderTemplateDetails />,
            },
            {
                path: ':id/:action',
                component: <ProviderTemplateDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    {
        path: '/provider-fields',
        component: <ProviderFields />,
        userRight: 'services.manageProviderTemplates.providerFields',
        children: [
            {
                path: 'add',
                component: <ProviderFieldDetails />,
            },
            {
                path: ':id/:action',
                component: <ProviderFieldDetails />,
                allowedActions: generalAccess,
            },
        ],
    },

    /* END CORE SQUAD ROUTES  */

    /* PARKING SQUAD ROUTES  */
    {
        path: '/tariffs',
        component: <Tariffs />,
        userRight: 'services.manageTariffs.tariffs',
        payed: true,
        children: [
            {
                path: 'add',
                component: <TariffDetails />,
            },
            {
                path: ':id/:action',
                component: <TariffDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    {
        path: '/site-assignments',
        component: <TariffAssignments />,
        userRight: 'services.manageTariffs.siteAssignments',
        payed: true,
    },
    {
        path: '/free-parking',
        component: <Configurations />,
        userRight: 'services.manageFreeParking.freeParking',
        children: [
            {
                path: 'add',
                component: <ConfigurationDetails />,
            },
            {
                path: ':id/:action',
                component: <ConfigurationDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    {
        path: '/configuration-assignments',
        component: <ConfigurationAssignments />,
        userRight: 'services.manageFreeParking.siteAssignments',
        children: [
            {
                path: ':id/:action',
                component: <ConfigurationAssignmentDetails />,
                allowedActions: generalAccess,
            },
        ],
    },
    /* END PARKING SQUAD ROUTES  */

    /* FLEET-NET SQUAD ROUTES SHOULD BE CHANGED HERE!!! */

    {
        path: '/fleetnet',
        component: <HostProvidersList />,
        userRight: 'services.manageFleetnets.fleetnet',
        children: [
            {
                path: 'add',
                component: <AddHostProvider />,
                allowedActions: generalAccess,
            },
            {
                path: ':id/:action',
                component: <HostProviderDetails />,
                allowedActions: generalAccess,
            },
            {
                path: 'card-issuer/:id/:action',
                component: <CardIssuerEditView />,
                allowedActions: generalAccess,
            },
        ],
    },

    {
        path: '/card-profiles',
        component: <CardProfileList />,
        userRight: 'services.manageFleetnets.fleetnet',
        children: [
            {
                path: 'add',
                component: <AddCardProfile />,
                allowedActions: generalAccess,
            },
            {
                path: ':id/:action',
                component: <CardProfileDetails />,
                allowedActions: [...generalAccess, Actions.Copy],
            },
        ],
    },
    {
        path: '/fleetnet-sites-devices',
        component: <FleetList />,
        userRight: 'services.manageFleets.fleets',
    },
    {
        path: '/fleetnet-sites',
        component: <FleetnetSitesList />,
        userRight: 'services.manageFleets.fleetnetSites',
        children: [
            {
                path: ':id/:action',
                component: <FleetnetSiteDetails />,
                allowedActions: [...generalAccess, Actions.Edit],
            },
        ],
    },

    {
        path: '/fleetnet-devices',
        component: <FleetnetDevicesList />,
        userRight: 'services.manageFleets.fleetnetDevices',
        children: [
            {
                path: ':id/:action',
                component: <FleetnetDeviceDetails />,
                allowedActions: [...generalAccess, Actions.Edit],
            },
        ],
    },

    /* END FLEET-NET SQUAD ROUTES */
];
