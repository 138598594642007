/**
 * Clears the local storage of idParent, deviceId and addOrEdit when leaving a component.
 * This is necessary because the user might navigate back to the component and we don't want the old values to be used.
 */
export const clearLSOnComponentLeave = () => {
    localStorage.removeItem('idParent');
    localStorage.removeItem('deviceId');
    localStorage.removeItem('addOrEdit');
};

/**
 * Clears all local storage when logging out.
 * This is necessary because the user might log in again with a different user and we don't want the old values to be used.
 */
export const clearLSOnLogout = () => {
    localStorage.clear();
};
