import { t } from 'i18next';
import * as Yup from 'yup';
import { ModuleField } from '../app/pages/interfaces';
export interface PasswordValidationError {
    value: string;
    exists: boolean;
}
interface ValidatePasswordModel {
    passwordValidations: PasswordValidationError[];
    password: string;
    validationSchema: () => Yup.SchemaOf<object>;
    setPasswordValidations(res: PasswordValidationError[]): void;
}

/**
 * Validates a password given a validation schema, and sets the password validation errors
 * in the component state.
 *
 * @param {ValidatePasswordModel} obj - An object containing the validation schema,
 * the password to validate, the current password validation errors, and a function
 * to set the password validation errors in the component state.
 *
 * @returns {Promise<void>} A promise that resolves when the validation is complete.
 */
export async function validatePassword({
    validationSchema,
    password,
    passwordValidations,
    setPasswordValidations,
}: ValidatePasswordModel): Promise<void> {
    try {
        const schema = validationSchema();
        await schema.validateAt('password', { password }, { abortEarly: false });
        const res = passwordValidations.map((err: PasswordValidationError) => {
            return {
                ...err,
                exists: false,
            };
        });
        setPasswordValidations(res);
    } catch (e: any) {
        if (passwordValidations.length === 0) {
            const res: PasswordValidationError[] = e.errors.map((err: string) => {
                return { value: err, exists: true };
            });
            setPasswordValidations(res);
        } else {
            const res: PasswordValidationError[] = passwordValidations.map(
                (err: PasswordValidationError) => {
                    const indexError = e.errors.findIndex((el: string) => el === err.value);
                    if (indexError === -1) {
                        return { value: err.value, exists: false };
                    }
                    return { value: err.value, exists: true };
                }
            );
            setPasswordValidations(res);
        }
    }
}

/**
 * Validate password on language change.
 * This function validates the password schema on language change.
 * It returns a list of validation errors.
 *
 * @param validationSchema - The password validation schema.
 * @returns A list of validation errors.
 */
export async function validatePasswordOnChangedLanguage(
    validationSchema: () => Yup.SchemaOf<object>
) {
    const schema = validationSchema();
    let emptyList = [];
    try {
        await schema.validateAt('password', { password: '' }, { abortEarly: false });
    } catch (e: any) {
        emptyList = e.errors.map((err: string) => {
            return { value: err, exists: true };
        });
    }
    return emptyList;
}

/**
 * Returns a Yup schema for a string field with the given minimum and maximum lengths.
 * If requiredField is true, the field is also required.
 * The error messages are translated using the t function.
 * @param min The minimum allowed length of the string.
 * @param max The maximum allowed length of the string.
 * @param field The name of the field, used to translate the error messages.
 * @param requiredField Whether the field is required or not.
 */
export function getStringValidations(
    min: number,
    max: number,
    field: string,
    requiredField?: boolean
) {
    let stringValidation = Yup.string().trim();
    if (requiredField)
        stringValidation = stringValidation.required(t(`general.validations.required.${field}`));
    return stringValidation
        .min(min, t(`general.validations.${field}MinLength`, { val: min }))
        .max(max, t(`general.validations.${field}MaxLength`, { val: max }));
}

/**
 * Returns a Yup validation schema for a password with the given
 * minimum and maximum length, and with the given field name and
 * required field status.
 *
 * The schema will validate that the password:
 * - has at least one uppercase letter
 * - has at least one lowercase letter
 * - has at least one number
 * - has at least one special character
 * - has a length between the given minimum and maximum length
 *
 * @param min - The minimum length of the password
 * @param max - The maximum length of the password
 * @param field - The name of the field to validate
 * @param requiredField - Whether the field is required
 * @returns The Yup validation schema
 */
export function getCognitoPasswordValidations(
    min: number,
    max: number,
    field: string,
    requiredField?: boolean
) {
    return getStringValidations(min, max, field, requiredField)
        .matches(/(?=.*[A-Z])/, {
            message: t('general.validations.password', {
                format: t('general.labels.uppercase'),
                regex: t('password.letter'),
            }),
        })
        .matches(/(?=.*[a-z])/, {
            message: t('general.validations.password', {
                format: t('general.labels.lowercase'),
                regex: t('password.letter'),
            }),
        })
        .matches(/(?=.*\d)/, {
            message: t('general.validations.password', {
                format: '',
                regex: t('password.number'),
            }),
        })
        .matches(/(?=.*[\^$\-*.[\]{}()?"!@#%&/,><':;|_~`])/, {
            message: t('general.validations.password', {
                format: '',
                regex: t('password.specialCharacter'),
            }),
        });
}

/**
 * Returns a Yup validation schema for a number field with the given minimum and maximum values.
 * The field is also required.
 * The error messages are translated using the t function.
 * @param min The minimum allowed value of the field.
 * @param max The maximum allowed value of the field.
 * @param field The name of the field, used to translate the error messages.
 */
export function getCoordinatesValidations(min: number, max: number, field: string) {
    return Yup.number()
        .nullable()
        .required(t(`general.validations.required.${field}`))
        .min(min, t(`general.validations.${field}MinNumber`, { val: min }))
        .max(
            max,
            t(`general.validations.${field}MaxNumber`, {
                val: max,
            })
        );
}

/**
 * Creates a validation schema for marketplace modules based on the given fields.
 *
 * @param {ModuleField[]} fields - An array of ModuleField objects representing the fields to include in the validation schema.
 * @return {Yup.ObjectSchema} - The validation schema generated from the fields.
 */
export const createValidationSchema = (fields: ModuleField[]) => {
    let partialSchema = Yup.object();
    fields.map((field: ModuleField) => {
        if (field.is_required) {
            switch (field.type) {
                case 'number':
                    partialSchema = partialSchema.concat(
                        Yup.object().shape({
                            [field.name]: Yup.number()
                                .nullable()
                                .when('active', {
                                    is: (active: boolean) => active === true,
                                    then: Yup.number()
                                        .nullable()
                                        .required(
                                            t('general.validations.requiredField', {
                                                val: field.name,
                                            })
                                        ),
                                }),
                        })
                    );
                    break;
                default:
                    partialSchema = partialSchema.concat(
                        Yup.object().shape({
                            [field.name]: Yup.string()
                                .nullable()
                                .when('active', {
                                    is: (active: boolean) => active === true,
                                    then: Yup.string().required(
                                        t('general.validations.requiredField', {
                                            val: field.name,
                                        })
                                    ),
                                }),
                        })
                    );
                    break;
            }
        }
    });
    return partialSchema;
};
